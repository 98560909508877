.container {
  max-width: 960px;
  margin: 1em auto;

  table {
    margin: 0 auto;
  }

  tr {
    margin-top: 0.5em;
  }

  td {
    padding: 0.5em;
  }

  .button {
    padding: 0.5em 1em;
  }
}
